import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import styled from "styled-components"

const NavWrapper = styled.div`
  width: 100%;
  .nav-link {
    color: #dddddd !important;
    &:hover {
      color: #959497 !important;
    }
  }
`
const GatsbyLink = props => (
  <Link to={props.href} className="nav-link">
    {props.children}
  </Link>
)

const Header = ({ siteTitle }) => (
  <NavWrapper>
    <Navbar id="navbar" expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={props => <GatsbyLink {...props} href="/" />}>
            Home
          </Nav.Link>
          <Nav.Link as={props => <GatsbyLink {...props} href="/about" />}>
            About Us
          </Nav.Link>
          <Nav.Link as={props => <GatsbyLink {...props} href="/services" />}>
            Services
          </Nav.Link>
          <Nav.Link as={props => <GatsbyLink {...props} href="/blog" />}>
            Blog
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Nav className="mr-auto">
        <Nav.Link as={props => <GatsbyLink {...props} href="/contact" />}>
          Contact
        </Nav.Link>
      </Nav>
    </Navbar>
  </NavWrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
