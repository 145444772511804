import React from "react"
//https://github.com/FormidableLabs/nuka-carousel
import Carousel from "nuka-carousel"
import Image from "./image"
import { Link } from "gatsby"

const MainSlider = () => {
  return (
    <Carousel withoutControls autoplay wrapAround>
      <Link to="/">
        <Image path="Slider-1_1.png" alt="test" />
      </Link>
      <Link to="/services/online-assessment">
        <Image path="Slider-2.png" alt="test" />
      </Link>
      <Link to="/services/strong-interest-inventory">
        <Image path="Slider-3.png" alt="test" />
      </Link>
      <Link to="/services/personality-preference-inventory">
        <Image path="Slider-4.png" alt="test" />
      </Link>
    </Carousel>
  )
}

export default MainSlider
