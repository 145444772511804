/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Image from "../components/image"
//https://react-bootstrap.netlify.com/layout/grid/#container-props
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Header from "./header"
//import "./layout.css"
import "./base.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Container
        fluid
        style={{
          padding: `1rem 3rem`,
          boxShadow: `-5px 26px 48px -10px #666666`,
        }}
      >
        <Row>
          <Col xs="5">
            <Image path="newHomeLogo1.png" />
          </Col>
          <Col xs="3" />
          <Col xs="4">
            <span
              style={{
                bottom: 0,
                right: 0,
                marginRight: `1.5rem`,
                position: `absolute`,
              }}
            >
              (631) 432-9192
            </span>
          </Col>
        </Row>

        <Row>
          <Header siteTitle={data.site.siteMetadata.title} />
        </Row>
        <Row>
          <main style={{ width: `100%`, backgroundColor: `white` }}>
            {children}
          </main>
        </Row>
        <Row>
          <footer style={{ margin: `0 auto` }}>
            © {new Date().getFullYear()}, Human Resource Potential Center
          </footer>
        </Row>
      </Container>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
