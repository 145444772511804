import React from "react"
import styled from "styled-components"
import { HeaderStyle } from "./styles/main"
const MissionStyle = styled.div`
  padding: 1rem 2rem;
`
const Mission = () => {
  return (
    <MissionStyle>
      <HeaderStyle>Our Mission</HeaderStyle>
      <p>
        At the Human Resource Potential Center, we have one mission – to provide
        organizations and individuals with the cutting-edge tools and consulting
        services that they need to meet and exceed the ever-changing demands of
        the modern global workplace.
      </p>
      <p>Our services include:</p>
      <ul>
        <li>
          Providing students with the guidance they need to select educational
          majors that reflect their interests and complement their personality
          traits.
        </li>
        <li>
          Providing career counselling that allows our clients to fulfill their
          potential as employees and as individuals.
        </li>
        <li>
          Providing human resources and employment law consulting services to
          organizations.
        </li>
        <li>
          Assisting in the recruitment of talented staff and in the design of
          effective development and retention programs.
        </li>
      </ul>
    </MissionStyle>
  )
}

export default Mission
